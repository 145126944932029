<template>
  <div id="application" class="application-container">
    <div class="header">
      <h1>应用场景</h1>
      <h2>多应用场景全覆盖，思腾云 AI 开放平台助力业务成功</h2>
    </div>
    <div class="body">
      <div class="row">
        <div class="application-item">
          <h1>大模型</h1>
          <div class="bg-box model"></div>
          <div class="content">
            <h2>大模型</h2>
            <p>让智能回答、聊天机器人、自动摘要、机器翻译、文本分类等任务得以实现。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>AIGC</h1>
          <div class="bg-box aigc"></div>
          <div class="content">
            <h2>AIGC</h2>
            <p>人工智能技术来生成各种类型的内容，比如文本、图像、音频、视频等。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>政府</h1>
          <div class="bg-box goverment"></div>
          <div class="content">
            <h2>政府</h2>
            <p>提升公共服务的效率与质量，强化公共安全，改进政策方案和决策流程等。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>自动驾驶</h1>
          <div class="bg-box auto-drive"></div>
          <div class="content">
            <h2>自动驾驶</h2>
            <p>让车辆能够自主感知环境、规划路径和控制行驶。</p>
          </div>
        </div>
      </div>
      <div class="row bottom">
        <div class="application-item">
          <h1>医疗</h1>
          <div class="bg-box medical"></div>
          <div class="content">
            <h2>医疗</h2>
            <p>报告智能剖析、互联网检测以及居民全生命周期健康管理等方面的应用。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>铁路</h1>
          <div class="bg-box railway"></div>
          <div class="content">
            <h2>铁路</h2>
            <p>达成列车智能调度、设备故障预测、铁路线路安全监控等功能的实现。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>矿山</h1>
          <div class="bg-box mine"></div>
          <div class="content">
            <h2>矿山</h2>
            <p>赋予矿山行业智能化、数字化、自动化的生产方案。</p>
          </div>
        </div>
        <div class="application-item">
          <h1>金融</h1>
          <div class="bg-box finance"></div>
          <div class="content">
            <h2>金融</h2>
            <p>为金融机构提供更加高效、智能化、精准的策略服务。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.application-container {
  background-color: #010101;
  overflow: hidden;
  padding-bottom: 360px;
  margin-top: -1px;
  .header {
    color: #ffffff;
    margin-top: 186px;
    h1 {
      font-family: SourceHanSansCN-Bold;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }
    h2 {
      font-family: SourceHanSansCN-Medium;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      margin-top: 30px;
    }
  }
  .body {
    margin-top: 150px;
    padding: 0 188px;
    .row {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
      &.bottom {
        margin-top: 12px;
      }
      .application-item {
        width: calc(25% - 12px);
        height: 50vh;
        color: #ffffff;
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        .bg-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transition: all linear 0.3s;
          &.model {
            background: url('../../../assets/img/portal/img_damoxing.png') no-repeat center;
            background-size: cover;
          }
          &.aigc {
            background: url('../../../assets/img/portal/img_AIGC.png') no-repeat center;
            background-size: cover;
          }
          &.goverment {
            background: url('../../../assets/img/portal/img_zhengfu.png') no-repeat center;
            background-size: cover;
          }
          &.auto-drive {
            background: url('../../../assets/img/portal/img_zidongjiashi.png') no-repeat center;
            background-size: cover;
          }
          &.medical {
            background: url('../../../assets/img/portal/img_yiliao.png') no-repeat center;
            background-size: cover;
          }
          &.railway {
            background: url('../../../assets/img/portal/img_tielu.png') no-repeat center;
            background-size: cover;
          }
          &.mine {
            background: url('../../../assets/img/portal/img_kuangshan.png') no-repeat center;
            background-size: cover;
          }
          &.finance {
            background: url('../../../assets/img/portal/img_jinrong.png') no-repeat center;
            background-size: cover;
          }
        }
        &:not(:first-child) {
          margin-left: 12px;
        }
        h1 {
          font-family: SourceHanSansCN-Bold;
          font-size: 30px;
          text-shadow: 0 2px 4px rgba(0,0,0,0.50);
          font-weight: 700;
          text-align: right;
          position: absolute;
          top: 30px;
          right: 30px;
          transition: all linear 0.3s;
          z-index: 1;
        }
        .content {
          position: absolute;
          bottom: -172px;
          left: 0;
          width: 100%;
          height: 160px;
          background-color: #3273F9;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 30px;
          transition: all linear 0.3s;
          h2 {
            font-family: SourceHanSansCN-Bold;
            font-size: 24px;
            font-weight: 700;
          }
          p {
            font-family: SourceHanSansCN-Medium;
            font-size: 14px;
            margin-top: 17px;
            letter-spacing: 1px;
          }
        }
        &:hover {
          .content {
            bottom: 0px;
          }
          h1 {
            top: -40px;
          }
          .bg-box {
            top: -160px;
          }
        }
      }
    }
  }
}
</style>
