<template>
  <div id="approve" class="approve-container">
    <div class="banner-reviews">
      <div class="approve-banner">
        <h1>行业认可</h1>
        <p>思腾云完善的服务体系得到大量客户的认可，服务客户覆盖多个行业</p>
      </div>
      <div class="user-reviews">
        <div class="user-reviews-item">
          <img src="../../../assets/img/portal/ic_1.png" alt="">
          <p>
            通过思腾云算力平台，
            我们以更经济的方式实现了
            预期效果，太让人惊喜了！
            大大节省预算，性价比超高！
          </p>
        </div>
        <div class="user-reviews-item">
          <img src="../../../assets/img/portal/ic_2.png" alt="">
          <p>
            思腾云服务团队非常优秀，
            能及时、高效地解决问题！
            给人带来极大的便利和安心！
          </p>
        </div>
        <div class="user-reviews-item">
          <img src="../../../assets/img/portal/ic_2.png" alt="">
          <p>
            平台上训练一个语言模型时，
            在处理长文本和复杂语义理解上，
            它的表现更为稳定。
          </p>
        </div>
        <div class="user-reviews-item">
          <img src="../../../assets/img/portal/ic_1.png" alt="">
          <p>
            思腾云平台的训练功能，
            让我感受到了科技的强大力！花最少的钱解决了我的需求！
          </p>
        </div>
      </div>
    </div>
    <div class="cooperative-units">
      <img src="../../../assets/img/portal/img_logo_zong.png" alt="">
    </div>
  </div>
</template>
<style lang="scss" scoped>
.approve-container {
  .banner-reviews {
    display: flex;
    .approve-banner {
      width: 60%;
      height: 820px;
      background: url('../../../assets/img/portal/ba_hangyerenke.png') no-repeat center;
      background-size: cover;
      overflow: hidden;
      h1 {
        font-family: SourceHanSansCN-Bold;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: 700;
        text-align: center;
        margin-top: 100px;
      }
      p {
        font-family: SourceHanSansCN-Medium;
        font-size: 18px;
        color: #FFFFFF;
        text-align: center;
        font-weight: 500;
        margin-top: 35px;
      }
    }
    .user-reviews {
      width: 40%;
      height: 820px;
      display: flex;
      flex-wrap: wrap;
      .user-reviews-item {
        width: 50%;
        flex-shrink: 0;
        padding-left: 60px;
        &:nth-child(1) {
          background-color: #000000;
        }
        &:nth-child(2) {
          background-color: #2B3B75;
        }
        &:nth-child(3) {
          background-color: #2B3B75;
        }
        &:nth-child(4) {
          background-color: #000000;
        }
        p {
          width: 208px;
          font-family: SourceHanSansCN-Medium;
          font-size: 16px;
          color: #FFFFFF;
          line-height: 34px;
          margin-top: 30px;
          padding-right: 16px;
        }
        img {
          width: 72px;
          height: 66px;
          margin-top: 42px;
        }
      }
    }
  }
  .cooperative-units {
    height: 680px;
    padding: 99px 162px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
