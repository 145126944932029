<template>
  <div class="footer">
    <div class="title">
      <h1>赋能AI，为AI 提供澎湃算力</h1>
    </div>
    <p>成为卓越的算力解决方案引领者，构筑AI世界的无限可能。</p>
    <div class="scale-box">
      <div class="scale-item">
        <!-- <svg-icon icon-class="ic_20"/> -->
         <img src="../../../assets/img/portal/ic_20.png" alt=""/>
        <p>为各行业提供多元化的算力服务，助力产业升级</p>
      </div>
      <div class="scale-item">
        <!-- <svg-icon icon-class="ic_201"/> -->
        <img src="../../../assets/img/portal/ic_201.png" alt=""/>
        <p>为各行业提供多元化的算力服务，助力产业升级</p>
      </div>
      <div class="scale-item">
        <!-- <svg-icon icon-class="ic_100"/> -->
        <img src="../../../assets/img/portal/ic_100.png" alt=""/>
        <p>为各行业提供多元化的算力服务，助力产业升级</p>
      </div>
    </div>
    <div class="hot-line-box">
      <div class="hot-line">
        服务热线：400-012-9522 转 2
      </div>
    </div>
    <div class="copyright-box">
      <p>
        Copyright © 2009-2024 思腾合力(天津)科技有限公司 All Rights Reserved
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">津ICP备19000271号-1</a>
      </p>
      <p>总部地址：天津经济技术开发区逸仙工业园翠浦道1号</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.footer {
  height: 2560px;
  background: url('../../../assets/img/portal/bg_ditu.png') no-repeat center;
  background-size: cover;
  overflow: hidden;
  .title {
    height: 100px;
    margin-top: 146px;
    background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 4%, rgba(255,255,255, 0.46) 50%, rgba(255,255,255,0.00) 97%);
    h1 {
      font-family: SourceHanSansCN-Bold;
      font-size: 32px;
      color: #0A4988;
      text-align: center;
      font-weight: 700;
      height: 100px;
      line-height: 100px;
    }
  }
  p {
    font-family: SourceHanSansCN-Medium;
    font-size: 30px;
    text-shadow: 0 2px 20px rgba(10,73,136,0.55);
    color: #FFFFFF;
    text-align: center;
    margin-top: 22px;
  }
  .scale-box {
    display: flex;
    justify-content: center;
    margin-top: 253px;
    padding: 0 16px;
    .scale-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(2) {
        margin: 0 110px;
      }
      img {
        width: 223.5px;
        height: 96px;
      }
      p {
        font-family: SourceHanSansCN-Bold;
        font-size: 20px;
        color: #0A4988;
        text-align: center;
        font-weight: 700;
      }
    }
  }
  .hot-line-box {
    display: flex;
    justify-content: center;
    margin-top: 1268px;
    .hot-line {
      width: 630px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      border: 1px solid #979797;
      background-color: rgba($color: #ffffff, $alpha: 0.73);
      font-family: SourceHanSansCN-Bold;
      font-size: 30px;
      color: #474747;
      font-weight: 700;
    }
  }
  .copyright-box {
    margin-top: 283px;
    p {
      text-align: center;
      font-family: SourceHanSansCN-Light;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
</style>
