import http from '@/utils/http'

const queryProductRecommendApi = (data: number) => {
  return http({
    method: 'get',
    url: `/user/productRecommend/queryProductRecommend/${data}`
  })
}

export {
  queryProductRecommendApi
}
