
import { defineComponent, onMounted, ref, reactive } from 'vue'
import HeaderNav from '@/components/header-nav/HeaderNav.vue'
import ProductRecommendation from './components/ProductRecommendation.vue'
import ProductAdvantage from './components/ProductAdvantage.vue'
import ApplicationScene from './components/ApplicationScene.vue'
import CustomerCase from './components/CustomerCase.vue'
import ProductArchitecture from './components/ProductArchitecture.vue'
import IndustryApprove from './components/IndustryApprove.vue'
import Footer from './components/Footer.vue'
import {
  queryProductRecommendApi
} from '@/api/portal'

export default defineComponent({
  components: {
    HeaderNav,
    ProductRecommendation,
    ProductAdvantage,
    ApplicationScene,
    CustomerCase,
    ProductArchitecture,
    IndustryApprove,
    Footer
  },
  setup () {
    const bannerHeight = ref<number>(0)
    const setBannerHeight = () => {
      const height = document.documentElement.clientHeight
      bannerHeight.value = height
      const bannerBox = document.querySelector('.banner-box') as HTMLElement
      bannerBox.style.height = height + 'px'
    }

    const currentAnchorPoint = ref<string>('recommendation')

    const handleTabs = (type: string) => {
      currentAnchorPoint.value = type
    }

    // 产品推荐
    const currentModelType = ref<number>(2)

    const gpuNumberList = ref<any[]>([])

    const currentGpuNum = ref<string>('')

    const gpuList = ref<any[]>([])
    const allGpuList: any = reactive({})

    const handleChangeModelType = (type: number) => {
      currentModelType.value = type
      gpuNumberList.value = []
      queryGpuManageModel()
    }

    const handleChangeGpuNum = (type: string) => {
      currentGpuNum.value = type
      gpuList.value = allGpuList[currentGpuNum.value]
    }

    const queryGpuManageModel = () => {
      queryProductRecommendApi(currentModelType.value).then(res => {
        Object.keys(res.data).forEach(key => {
          gpuNumberList.value.push(key)
        })
        currentGpuNum.value = gpuNumberList.value[0]
        gpuList.value = res.data[currentGpuNum.value]
        Object.assign(allGpuList, res.data)
      })
    }

    // 监听屏幕滚动
    const contentRef = ref()
    const isPositionFixed = ref<boolean>(false)
    const handleScroll = () => {
      const a = document.querySelector('.content') as HTMLElement
      if (a.scrollTop >= 900 && a.scrollTop < 1900) {
        currentAnchorPoint.value = 'recommendation'
      } else if (a.scrollTop > 1900 && a.scrollTop < 3000) {
        currentAnchorPoint.value = 'advantage'
      } else if (a.scrollTop > 3000 && a.scrollTop < 5200) {
        currentAnchorPoint.value = 'application'
      } else if (a.scrollTop > 5200 && a.scrollTop < 6000) {
        currentAnchorPoint.value = 'case'
      } else if (a.scrollTop > 6000 && a.scrollTop < 7900) {
        currentAnchorPoint.value = 'architecture'
      } else if (a.scrollTop > 7900) {
        currentAnchorPoint.value = 'approve'
      }
      if (a.scrollTop > bannerHeight.value) {
        isPositionFixed.value = true
      } else {
        isPositionFixed.value = false
      }
    }

    // 联系我们
    const handleMouseEnter = () => {
      const a = document.querySelector('.connect-content') as HTMLElement
      a.style.opacity = '1'
    }

    const handleMouseLeave = () => {
      const a = document.querySelector('.connect-content') as HTMLElement
      a.style.opacity = '0'
    }

    // 返回顶部
    const handleScrollTop = () => {
      const a = document.querySelector('.content') as HTMLElement
      a.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }

    const handleHelpCenter = () => {
      window.open('https://docs.aiserver.cn/SitonCloud/introduction/', '_blank')
    }

    onMounted(() => {
      setBannerHeight()
      queryGpuManageModel()
      contentRef.value.addEventListener('scroll', handleScroll)
    })

    return {
      currentAnchorPoint,
      handleTabs,
      currentModelType,
      handleChangeModelType,
      gpuNumberList,
      currentGpuNum,
      handleChangeGpuNum,
      gpuList,
      contentRef,
      handleMouseEnter,
      handleMouseLeave,
      handleScrollTop,
      handleHelpCenter,
      isPositionFixed
    }
  }
})
