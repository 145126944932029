
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  emits: ['change', 'changeGpuNu'],
  props: {
    currentModelType: {
      type: Number,
      required: true,
      default: 0
    },
    gpuNumberList: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => []
    },
    currentGpuNum: {
      type: String,
      required: true,
      default: ''
    },
    gpuList: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => []
    }
  },
  setup (_, { emit }) {
    const handleTabs = (type: number) => {
      emit('change', type)
    }

    const handleChangeGpuNum = (type: string) => {
      emit('changeGpuNu', type)
    }

    return {
      handleTabs,
      handleChangeGpuNum
    }
  }
})
