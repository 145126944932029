<template>
  <div id="advantage" class="advantage-container">
    <div class="title">
      <h1>产品优势</h1>
    </div>
    <div class="desc">
      <p>
        <svg-icon icon-class="ic_fuhao"/>
        <span>为什么要选择思腾云的AI平台算力服务？</span>
      </p>
      <p>
        <span>思腾合力</span>
        <b>自主研发</b>
        <span>(非K8S)的AI 开放平台是一款专为深度学习和机器学习而设计的先进 AI 训推专用平台。</span>
      </p>
    </div>
    <div class="special-box">
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_moxing"/>
            <h1>模型训练</h1>
          </div>
        </div>
        <div class="special-body">
          <h2>集成主流AI框架：</h2>
          <ul>
            <li>
              1.TensorFlow、Pytorch、国内的Paddlepaddle、Mindspore等。
            </li>
            <li>
              2.提供灵活的 Jupyter、Lab、VScode交互式训练环境。
            </li>
            <li>
              3.支持大模型训练。
            </li>
          </ul>
        </div>
      </div>
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_cunchu"/>
            <h1>存储中心</h1>
          </div>
        </div>
        <div class="special-body">
          <ul>
            <li>
              1.支持高性能分布式存储、对象存储、文件存储。
            </li>
            <li>
              2.支持大文件高速上传。
            </li>
            <li>
              3.提供完善的数据隔离机制以及严格的安全管理功能，确保用户数据私有化。
            </li>
          </ul>
        </div>
      </div>
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_biaozhu"/>
            <h1>数据标注&视频切帧</h1>
          </div>
        </div>
        <div class="special-body">
          <ul>
            <li>
              1.先进的图片数据标注工具，支持矩形圈选标记特征区域，提高了数据标注准确性。
            </li>
            <li>
              2.可将视频按帧切分成图片序列，适用于提取关键帧的需求，支持精细化图像分析。
            </li>
            <li>
              3.拓展了平台应用范围，简化了数据准备和图像处理过程。
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="special-box bottom">
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_GPU"/>
            <h1>GPU插拔/切分</h1>
          </div>
        </div>
        <div class="special-body">
          <ul>
            <li>
              1.自研调度系统：精准识别、分、管理、监控和弹性调度/插拔运算卡等异构资源，并提供分布式并行训练能力。
            </li>
            <li>
              2.插拔运算卡功能：平台支持无卡模式，用户可随时开启任务进行环境配置与传输数据。
            </li>
            <li>
              3.支持特定运算卡切分功能，最小显存单位GB。
            </li>
          </ul>
        </div>
      </div>
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_yunwei"/>
            <h1>运维&计费</h1>
          </div>
        </div>
        <div class="special-body">
          <ul>
            <li>
              1.国产化计算设备纳管，分钟级监控设备运行状态，提供故障告警功能。
            </li>
            <li>
              2.管理员可自定义配置告警阈值，并支持微信、钉钉进行告警推送。
            </li>
            <li>
              3.精准的计费系统，杜绝人为错误，提升工作效率，节省人力成本，费用报表长期存储随时可查。
            </li>
          </ul>
        </div>
      </div>
      <div class="special-item">
        <div class="special-header">
          <div class="header-title">
            <svg-icon icon-class="ic_renwu"/>
            <h1>任务流</h1>
          </div>
        </div>
        <div class="special-body">
          <ul>
            <li>
              1.通过拖拉拽操作选择不同的数据处理模块，可以选择不同的AI模型。
            </li>
            <li>
              2.设置训练参数启动训练，利用自动化科学技术，完成特定AI训练任务。
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.advantage-container {
  background-color: #010101;
  height: 1413px;
  color: #ffffff;
  padding-left: 240px;
  overflow: hidden;
  background-image: url('../../../assets/img/portal/bg_peitu.png');
  background-repeat: no-repeat;
  background-size: 74.6% 100%;
  background-position: right;
  .title {
    margin-top: 174px;
    h1 {
      font-family: SourceHanSansCN-Bold;
      font-weight: 700;
      font-size: 32px;
      background: linear-gradient(to right, #C7C3C3, #FFFFFF, #B1ADAD);
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .desc {
    margin-top: 33px;
    p {
      &:nth-child(2) {
        margin-top: 17px;
      }
      svg {
        width: 16px;
        height: 14px;
      }
      font-size: 20px;
      font-family: SourceHanSansCN-Medium;
      b {
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
  .special-box {
    display: flex;
    margin-top: 123px;
    .special-item {
      width: 310px;
      height: 380px;
      background: url('../../../assets/img/portal/png_biankuang.png') no-repeat center;
      background-size: cover;
      padding: 0 16px;
      &:not(:first-child) {
        margin-left: 24px;
      }
      .special-header {
        margin-top: 20px;
        &::after {
          content: '';
          display: block;
          width: 233px;
          height: 1px;
          background-image: linear-gradient(90deg, #FFFFFF 0%, rgba(0,0,0,0.00) 100%);
          margin-top: 18px;
        }
        .header-title {
          display: flex;
          align-items: center;
          svg {
            width: 20px;
            height: 20px;
          }
          h1 {
            font-family: SourceHanSansCN-Bold;
            font-size: 20px;
            font-weight: 700;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            background-image: linear-gradient(to right, #FFFFFF, #A7A3A3, #FFFFFF);
            margin-left: 12px;
          }
        }
      }
      .special-body {
        font-family: SourceHanSansCN-Bold;
        font-weight: 700;
        margin-top: 18px;
        h2 {
          font-size: 14px;
        }
        ul {
          li {
            font-size: 14px;
            margin-top: 18px;
            letter-spacing: 1px;
          }
        }
      }
    }
    &.bottom {
      margin-top: 26px;
    }
  }
}
</style>
