<template>
  <div id="architecture" class="architecture-container">
    <div class="header">
      <div class="title">
        <h1>产品架构</h1>
      </div>
    </div>
    <div class="desc">
      <p>构建于创新的容器技术之上，集成了专门为人工智能开发所需的各类软件工具。提供了一个高效便捷的环境，且</p>
      <p>支持用户快速搭建深度学习和机器学习的开发环境，在加速各行各业AI应用场景的落地过程中，发挥了重要作用。</p>
    </div>
    <div class="scene-ai">
      <p>
        AI应用：智慧医疗、智慧城市、智慧交通、智慧农业、无人驾驶、推荐系统、大模型服务…
      </p>
    </div>
    <div class="scene-modal">
      <p>
        大模型：智谱华章ChatGLM，阿里通义千问，百川大模型，华为盘古，百度文心一言，美团光年之外，科大讯飞星火，腾讯混元商汤日日新、字节豆包，360智脑，京东言犀，复旦MOSS，中科院紫东太初，智源悟道，云从科技从容
      </p>
    </div>
    <div class="quan">
      <svg-icon icon-class="svg_bg"/>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.architecture-container {
  margin-top: 79px;
  background-color: #000000;
  overflow: hidden;
  .header {
    margin-top: 145px;
    display: flex;
    justify-content: center;
    .title {
      width: 1185px;
      height: 72px;
      background: url('../../../assets/img/portal/img_jiagou.png') no-repeat center;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      h1 {
        font-family: SourceHanSansCN-Bold;
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  .desc {
    color: #ffffff;
    margin-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      width: 1015px;
      font-family: SourceHanSansCN-Medium;
      font-size: 20px;
      line-height: 30px;
    }
  }
  .scene-ai {
    color: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 124px;
    p {
      width: 640px;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      text-shadow: 0 2px 4px #1176FF;
      font-weight: 700;
    }
  }
  .scene-modal {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    p {
      width: 956px;
      font-family: SourceHanSansCN-Bold;
      font-size: 16px;
      text-shadow: 0 2px 4px #1176FF;
      font-weight: 700;
    }
  }
  .quan {
    height: 100vh;
    margin-top: 10px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
