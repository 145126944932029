
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  setup () {
    const list = ref([
      {
        titleIcon: 'ic_kexue',
        title: '科学智能',
        content: '在新闻、文学创作、广告、设计等行业，AIGC 可以辅助创作、提高效率、提供新的创意和思路。',
        bgImg: require('@/assets/img/portal/img_kexue.png'),
        activeContent: {
          title: '科学智能',
          introduction: [
            '人工智能计算平台建设方案',
            '通过自主研发的思腾合力AI开放平台解决客户的需求。',
            '思腾合力AI开放平台面向大规模异构计算基础设施管理，能够实现深度学习计算资源管理、调度、应用的自动化',
            '大幅提升计算基础设施资源利用效率，降低人工智能计算平台的总体拥有成本，提升人工智能研发创新效率。'
          ],
          caseIcon: 'ic_logo1'
        }
      },
      {
        titleIcon: 'ic_AI_lan',
        title: 'AIGC',
        content: '在新闻、文学创作、广告、设计等行业，AIGC 可以辅助创作、提高效率、提供新的创意和思路。',
        bgImg: require('@/assets/img/portal/img_ai.png'),
        activeContent: {
          title: 'AIGC',
          introduction: [
            '个人用户需求：个人用户可以将普通照片转化为有趣的漫画作品。',
            '商业客户解决方案：商业客户可以利用生成式AI技术，将产品或品牌的形象转化为生动有趣的漫画形式，制作漫画广告、宣传品等。',
            '提高创意性和新颖性：吸引更多的用户关注和喜爱。',
            '增强交互性和娱乐性：生成的漫画作品可用于制作各种有趣的表情包、头像等。'
          ],
          caseIcon: 'ic_logo1'
        }
      },
      {
        titleIcon: 'ic_robort_lan',
        title: '机器人',
        content: '机器人能够模拟人类行为和思维的机器，可以完成各种任务，例如搬运、焊接、装配、医疗、教育等。',
        bgImg: require('@/assets/img/portal/img_robort.png'),
        activeContent: {
          title: '机器人',
          introduction: [
            '思腾合力多节点8卡GPU服务器作为底层算力平台支撑，加速长输油管道及油气田运维工作的数字化和智能化转型升级。',
            '鹰瞳科技通过底层思腾合力企业级服务器的支撑，保障了算法研究顺利进行，更为视网膜数据分析提供了强大的算力。',
            '目前新石器已在全球率先完成L4级无人车产品商业化落地和规模化交付。'
          ],
          caseIcon: 'ic_logo3'
        }
      },
      {
        titleIcon: 'ic_yuanyuzhou_lan',
        title: '元宇宙',
        content: '元宇宙是利用科技手段进行链接与创造的，与现实世界映射与交互的虚拟世界，具备新型社会体系的数字生活空间。',
        bgImg: require('@/assets/img/portal/img_yuanyuzhou.png'),
        activeContent: {
          title: '元宇宙',
          introduction: [
            '通过将 AI 技术与游戏产业融合创新，配合全新的计算卡以及 AI SDK的应用，性能比上一代产品提升数十倍，加速了研发创新效率。',
            '算力弹性调用，对游戏团队提供高弹性 SaaS 服务，助力游戏业务快速上线。',
            '加速在 AI 算法、模仿学习、强化学习、演化学习等技术上并不断研发创新。',
            '可在云平台应用上不断探索新落地场景。'
          ],
          caseIcon: 'ic_logo4'
        }
      },
      {
        titleIcon: 'ic_yuyin_lan',
        title: '语音识别',
        content: '将人类的语音信号转换为相应的文字或指令的技术，比如智能语音助手。',
        bgImg: require('@/assets/img/portal/img_yuyin.png'),
        activeContent: {
          title: '语音识别',
          introduction: [
            '云知声成立于2012年，是国内领先的智能语音人工智能企业。',
            '思腾合力向云知声提供了大量的AI算力设备，帮助云知声在信号、语音、图像、文本的感知和表达能力，知识、理解、分析、决策等认知技术方面保持领先地位。',
            '借助思腾合力AI服务器搭建的集群规模已达到数千万。'
          ],
          caseIcon: 'ic_logo5'
        }
      }
    ])

    const currentIndex = ref<number>(0)

    const handleMouseEnter = (index: number) => {
      currentIndex.value = index
    }

    const getWidth = () => {
      const width = document.querySelector('.is-active')?.clientWidth
      const htmlArr: any = document.querySelectorAll('.case-content')
      for (const i of htmlArr) {
        i.style.width = width + 'px'
      }
    }

    onMounted(() => {
      getWidth()
    })

    return {
      list,
      currentIndex,
      handleMouseEnter
    }
  }
})
